import OauthClient, { TokenSet } from '@nn/oauth-client';

export interface WindowOauth2 {
  /**
  * Initializes the Auth2 Authorization code flow from you app
  * Note 1: The tokenset returned by this function will be kept fresh automatically.
  *   Store it "byRef" so you always have access to the updated values of the tokenset.
  * Note 2: Call this function ONLY ONCE at the start of your app, you don't have to call this
  *   again if you are interested in the latest tokenset, use "getTokenset" instead
  * @param {string} key the key that corresponds to the oauth2 application
  * @return {promise} A promise with the tokenset if available, if no tokenset is available
  * this will result in a redirect and a unresolved promise
  */
  initializeAuthorizationCodeFlow: (key: string) => Promise<TokenSet>;

  /**
  * deprecated implementation of "initializeAuthorizationCodeFlow"
  */
  signIn: (key: string) => Promise<TokenSet>;

  /**
  * returns the latest tokenset from sessionStorage
  * @param {string} key the key that corresponds to the oauth2 application
  * @return {promise<tokenSet | null>} A promise with the tokenset if available
  */
  getTokenset: (key: string) => Promise<TokenSet>;

  /**
  * Removes the tokenset and sessioninfomation from sessionStorage
  * @param {string} key the key that corresponds to the oauth2 application
  * @return {promise} A promise when done
  */
  cleanupSessionState: (key: string) => Promise<void>;

  /**
  * deprecated implementation of "cleanupSessionState"
  */
  signOut: (key: string) => Promise<void>;

  /**
  * Handles the Oauth2 Authorization Code Flow callback logic.
  * Stores the sessionInformation of the user to validate in a later stage if the
  *   tokenset still belongs to this user.
  * @param {string} key the key that corresponds to the oauth2 application
  * @return {promise} A promise when done
  */
  handleAuthorizationCodeFlowCallback: (key: string) => Promise<void>;

  /**
  * deprecated implementation of "handleAuthorizationCodeFlowCallback"
  */
  signInCallback: (key: string) => Promise<void>;

  /**
   * Checks if the user has an SSO session (currently at the SRP)
   */
  hasSsoCookie: () => Promise<boolean>;

  /**
   * Retrieve the client singleton instance
   */
  getClient: () => OauthClient;

  /**
   * Resets the loaded client so it will re-initialise when invoking one of the core functions.
   * Primarily usecase is for testing; we want to clear the singleton implementation
   */
  resetLoadedClient: () => void;

  /**
   * developer mode; enable additional debug logging for
   * tracking of authorization flow
   */
  setDebugLoggingEnabled: (isEnabled: boolean) => void;
}

/**
 * Type predicate function to evaluate if the given object is actually an error
 * This is because thrown errors can be of any type and as such you need to assert
 * this yourself to make typescript happy
 *
 * for more references see https://www.typescriptlang.org/docs/handbook/advanced-types.html#using-type-predicates
 *   and https://devblogs.microsoft.com/typescript/announcing-typescript-4-0-beta/#unknown-on-catch
 *
 * This function evaluates if if 'conforms' to the interface of an Error
 * so the rest of the code can make verified assumptions about the object
 *
 * @param error
 * @returns
 */
export function isError(error: unknown): error is Error {
  return (
    typeof error === 'object'
    && error !== null
    && 'message' in error
    && typeof (error as Record<string, unknown>).message === 'string'
  );
}
