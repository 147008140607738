import OauthClient from '@nn/oauth-client';

type OauthClientInfo = {
    key: string;
    client: OauthClient;
    initialized: boolean;
};

type OauthClientStore = {
    [key: string]: OauthClientInfo;
};

export default class OauthClientManager {
    private data: OauthClientStore = {};

    private recentKey: string;

    addClient(key: string, clientObj: OauthClient) {
      this.recentKey = key;
      this.data[key] = {
        key,
        client: clientObj,
        initialized: false,
      };
    }

    getClient(key: string): OauthClientInfo | undefined {
      this.recentKey = key;
      return this.data[key];
    }

    getRecentClient(): OauthClient | undefined {
      return this.data[this.recentKey]?.client;
    }

    initializeClient(key: string): void {
      this.recentKey = key;
      if (this.data[key]) {
        this.data[key].initialized = true;
      } else {
        console.error('Client not found!');
      }
    }
}
