import { isError } from './types';
import { KeyManagerConfiguration } from './configuration';

const readByContentType = async (response: Response) => {
  const contentType = response.headers.get('content-type');

  if (contentType.indexOf('application/json') !== -1) {
    return response.json();
  }

  return response.text();
};

async function call(url: string, opts: RequestInit) {
  const response = await fetch(url, opts);
  if (response.ok) {
    return readByContentType(response);
  }
  throw response;
}

export async function post(url: string, body: unknown, props: RequestInit) {
  const opts = {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify(body),
    ...props,
  } as RequestInit;

  return call(url, opts);
}

export async function get(url: string, props: RequestInit) {
  const opts = {
    method: 'GET',
    credentials: 'same-origin',
    ...props,
  } as RequestInit;

  return call(url, opts);
}

/**
 * Specific SRP endpoint that will sync the LTPA2 cookie with the AuthSessionId from forgerock
 * This is done because both SSO sessions have a different lifetime.
 * SRP is always leading here
 *
 * @returns
 */
export const syncAuthSessionId = (conf: KeyManagerConfiguration) => post(
  conf.AUTH_ENDPOINT,
  undefined, {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Accept-API-Version': 'resource=2.0, protocol=1.0',
      'x-requested-with': 'XMLHttpRequest',
    },
  },
);

function checkLoginServiceWithIE() {
  return new Promise((resolve, reject) => {
    const originalUrl = '/loginService';
    const xhttp = new XMLHttpRequest();

    xhttp.onreadystatechange = function onreadystatechange() {
      if (this.readyState === 4 && this.status === 200) {
        if (xhttp.response.includes('<html')) {
          reject(new Error('User is not logged in.'));
        } else {
          resolve(null);
        }
      }
    };

    xhttp.open('GET', originalUrl);
    xhttp.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhttp.send();
  });
}

function isIE() {
  const { userAgent } = window.navigator;
  return /MSIE|Trident/.test(userAgent);
}

/**
 * Verify if we have an SSO cookie (LTPAtoken) which we might be able to exchange
 * for a WSO2 oauth token
 * @returns
 */
export const hasSsoCookie = async (): Promise<boolean> => {
  try {
    if (isIE()) {
      await checkLoginServiceWithIE();
    } else {
      await get('/loginService', { redirect: 'manual' });
    }
    // if the page succeeds to load we assume that we are logged in (200 OK)
    return true;
  } catch (_ex) {
    return false;
  }
};

export interface SessionInfo {
  username: string;
  maxSessionExpirationTime: Date;
}

/**
 * Retrieve the absolute expiration of the session. This is enforced in the Secure Reverse Proxy.
 * The user is not able to extend this and needs to be forced to re-login.
 *
 * @returns
 */
export const getSessionInfo = async (conf: KeyManagerConfiguration):
Promise<SessionInfo | null> => {
  try {
    const json = await post(conf.SESSION_ENDPOINT, undefined, {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Accept-API-Version': 'resource=2.0, protocol=1.0',
        'x-requested-with': 'XMLHttpRequest',
      },
    });

    return {
      username: json.username,
      maxSessionExpirationTime: new Date(json.maxSessionExpirationTime),
    };
  } catch (err) {
    if (isError(err)) {
      console.warn(`Could not retrieve session info from /am/sso/sessions: ${err.message}`);
    }
    return null;
  }
};
