
const AS = 'as-c0/statics-oauth-client-nn';
const EP = 'statics-oauth-client-nn';


if (!window.location.origin) {
  // polyfill window.location.origin for browsers not supporting this
  // @ts-ignore
  window.location.origin = `${window.location.protocol}//${window.location.host}${window.location.port ? (`:${window.location.port}`) : ''}`;
}

if (window.location.origin.includes('-as.nn.insim.biz')) {
  __webpack_public_path__ = `${window.location.origin}/${AS}/`;
} else {
  __webpack_public_path__ = `${window.location.origin}/${EP}/`;
}