export interface KeyManagerConfiguration {
  /**
   * AUTHENICATION ENDPOINT IN FORGEROCK AS KNOWN IN THE PROXY (SRP)
   */
  AUTH_ENDPOINT: string;
  /**
   * SESSION ENDPOINT IN FORGEROCK AS KNOWN IN THE PROXY (SRP)
   */
  SESSION_ENDPOINT: string;
  /**
   * OAUTH ENDPOINT PREFIX AS KNOWN IN THE PROXY (SRP)
   */
  OAUTH_AUTHORITY_PREFIX: string;
  /** REALM NAME STRING REPRESENTATION
   */
  NAME: string;
}
export interface ConfigurationItem {
  /**
   * ClientID of the Application in WSO2
   */
  CLIENTID: string;
  /**
   * Tenant in WSO2 where the Application(client-id) lives
   */
  TENANTID: 'nn.nl';
  /**
   * Unique key in session storage where your applications session information will be stored
   * Needs to be unique otherwise (example) retailapp could overwrite the session information
   * of advisor.
   */
  STORAGEKEY: string;
  /**
   * When configuring the (relative) logoutUrl this allows this library
   * to initiate a logout when your absolute session expires at the
   * identity provider (ForgeRock)
   */
  LOGOUTURL?: string;
  /**
   * Configure this to differentiate from the default (relative) callback url when needed
   * Defaults to '/Oauth2Callback.htm' when absent
   */
  CALLBACKURL?: string;

  /**
   * Configure this to change the scopes that you need.
   * Defaults to ['profile'] when not provided.
   */
  SCOPES?: string[];

  /**
   * REALM in WSO2 where the Application(client-id) lives
   */
  REALM: KeyManagerConfiguration;
}

const internalRealm: KeyManagerConfiguration = {
  AUTH_ENDPOINT: '/as-c0/am/json/authenticate',
  OAUTH_AUTHORITY_PREFIX: '/as-c0/am/oauth2',
  SESSION_ENDPOINT: '/as-c0/am/sso/sessions?_action=getSessionInfo',
  NAME: 'internal',
};

const externalRealm: KeyManagerConfiguration = {
  AUTH_ENDPOINT: '/am/sso/authenticate',
  OAUTH_AUTHORITY_PREFIX: '/oauth2',
  SESSION_ENDPOINT: '/am/sso/sessions?_action=getSessionInfo',
  NAME: 'external',
};

const configurations: Record<string, ConfigurationItem> = {
  'advisor-local': {
    CLIENTID: '436f54c2-ba3a-464e-95a4-18c3b09ae48d',
    TENANTID: 'nn.nl',
    STORAGEKEY: 'advisor-local-oauth-state',
    REALM: externalRealm,
  },
  localhost: {
    CLIENTID: 'somelocalclientId',
    TENANTID: 'nn.nl',
    STORAGEKEY: 'localhost-oauth-state',
    LOGOUTURL: '/loggedout.html',
    REALM: externalRealm,
    SCOPES: ['profile'],
  },
  'callops-local': {
    CLIENTID: 'somelocalclientId',
    TENANTID: 'nn.nl',
    REALM: internalRealm,
    STORAGEKEY: 'callops-local-oauth-state',
    CALLBACKURL: '/as-c0/internal-employees-login-page/',
    LOGOUTURL: '/logout',
    SCOPES: ['internal', 'profile'],
  },
  'callops-dev': {
    CLIENTID: '2a4420d5-cada-4ec9-a41c-b6bf58875335',
    TENANTID: 'nn.nl',
    REALM: internalRealm,
    STORAGEKEY: 'callops-dev-oauth-state',
    CALLBACKURL: '/as-c0/internal-employees-login-page/',
    LOGOUTURL: '/logout',
    SCOPES: ['internal', 'profile'],
  },
  'callops-tst': {
    CLIENTID: 'a637d42e-9129-4517-9dc9-02375595d80f',
    TENANTID: 'nn.nl',
    REALM: internalRealm,
    STORAGEKEY: 'callops-tst-oauth-state',
    CALLBACKURL: '/as-c0/internal-employees-login-page/',
    LOGOUTURL: '/logout',
    SCOPES: ['internal', 'profile'],
  },
  'callops-acc': {
    CLIENTID: 'd38bf387-10b7-42c5-a77e-e749475188b3',
    TENANTID: 'nn.nl',
    REALM: internalRealm,
    STORAGEKEY: 'callops-acc-oauth-state',
    CALLBACKURL: '/as-c0/internal-employees-login-page/',
    LOGOUTURL: '/logout',
    SCOPES: ['internal', 'profile'],
  },
  'callops-prd': {
    CLIENTID: '852f05d8-1f5c-437f-9803-33bb9d1f5425',
    TENANTID: 'nn.nl',
    REALM: internalRealm,
    STORAGEKEY: 'callops-prd-oauth-state',
    CALLBACKURL: '/as-c0/internal-employees-login-page/',
    LOGOUTURL: '/logout',
    SCOPES: ['internal', 'profile'],
  },
  callops: {
    CLIENTID: '852f05d8-1f5c-437f-9803-33bb9d1f5425',
    TENANTID: 'nn.nl',
    REALM: internalRealm,
    STORAGEKEY: 'callops-prd-oauth-state',
    CALLBACKURL: '/as-c0/internal-employees-login-page/',
    LOGOUTURL: '/logout',
    SCOPES: ['internal', 'profile'],
  },
  'advisor-tst': {
    CLIENTID: 'b57e11d4-d346-4895-8268-9f1fbcf032c5',
    TENANTID: 'nn.nl',
    STORAGEKEY: 'adviseur-tst-oauth-state',
    LOGOUTURL: '/va/U-bent-uitgelogd.htm',
    REALM: externalRealm,
    SCOPES: ['profile', 'intermediary'],
  },
  'advisor-acc': {
    CLIENTID: 'd4fe75f0-5074-4c07-aa5f-a8c198f4da78',
    TENANTID: 'nn.nl',
    STORAGEKEY: 'adviseur-acc-oauth-state',
    LOGOUTURL: '/va/U-bent-uitgelogd.htm',
    REALM: externalRealm,
    SCOPES: ['profile', 'intermediary'],
  },
  advisor: {
    CLIENTID: '7f282989-5fe3-456f-b202-62cc15f17169',
    TENANTID: 'nn.nl',
    STORAGEKEY: 'adviseur-oauth-state',
    LOGOUTURL: '/va/U-bent-uitgelogd.htm',
    REALM: externalRealm,
    SCOPES: ['profile', 'intermediary'],
  },
  'mijnnn-tst': {
    CLIENTID: '9ca979f9-7334-4a7a-94a3-35fb407eb45e',
    TENANTID: 'nn.nl',
    SCOPES: ['profile', 'retail-customer:profile', 'customer'],
    STORAGEKEY: 'mijnnn-tst-oauth-state',
    LOGOUTURL: '/nn/U-bent-uitgelogd-1.htm',
    REALM: externalRealm,
  },
  'mijnnn-acc': {
    CLIENTID: '7f4656d3-6ae3-453a-9e3c-6ac01e9fc3dd',
    TENANTID: 'nn.nl',
    SCOPES: ['profile', 'retail-customer:profile', 'customer'],
    STORAGEKEY: 'mijnnn-acc-oauth-state',
    LOGOUTURL: '/Je-bent-uitgelogd.htm',
    REALM: externalRealm,
  },
  mijnnn: {
    CLIENTID: '3eb2421b-d9ef-4462-8ac3-9fd5e1af4b9c',
    TENANTID: 'nn.nl',
    STORAGEKEY: 'mijnnn-oauth-state',
    SCOPES: ['profile', 'retail-customer:profile', 'customer'],
    LOGOUTURL: '/Je-bent-uitgelogd-1.htm',
    REALM: externalRealm,
  },
  'mijn-nn-zakelijk-portal-tst': {
    CLIENTID: 'e9a29582-a814-48b4-91a1-84cf05850704',
    TENANTID: 'nn.nl',
    STORAGEKEY: 'mijnnnzakelijk-tst-oauth-state',
    SCOPES: ['profile', 'businessCustomer'],
    LOGOUTURL: '/U-bent-uitgelogd-Zakelijk.htm',
    CALLBACKURL: '/Oauth2CallbackMijnNNZakelijkPortal.htm',
    REALM: externalRealm,
  },
  'mijn-nn-zakelijk-portal-acc': {
    CLIENTID: 'c16d91a1-2f28-47f4-aac1-b11864df6f5f',
    TENANTID: 'nn.nl',
    STORAGEKEY: 'mijnnnzakelijk-acc-oauth-state',
    SCOPES: ['profile', 'businessCustomer'],
    LOGOUTURL: '/U-bent-uitgelogd-Zakelijk.htm',
    CALLBACKURL: '/Oauth2CallbackMijnNNZakelijkPortal.htm',
    REALM: externalRealm,
  },
  'mijn-nn-zakelijk-portal': {
    CLIENTID: '00ce1a08-6a24-448c-9762-f28a4e9364f2',
    TENANTID: 'nn.nl',
    STORAGEKEY: 'mijnnnzakelijk-oauth-state',
    SCOPES: ['profile', 'businessCustomer'],
    LOGOUTURL: '/U-bent-uitgelogd-Zakelijk.htm',
    CALLBACKURL: '/Oauth2CallbackMijnNNZakelijkPortal.htm',
    REALM: externalRealm,
  },
};

export default configurations;
