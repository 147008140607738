/* eslint-disable */
import './webpackPublicPath'; // must be imported first
import OauthClient, { TokenSet } from '@nn/oauth-client';
import { ILogger } from '@nn/oauth-client/types/interfaces';

import configuration from './configuration';
import { hasSsoCookie, getSessionInfo, SessionInfo } from './http';
import { WindowOauth2 } from './types';

import OauthClientManager from './oauthClientStore';

const DEFAULT_RELATIVE_OAUTH_CALLBACK_URL = '/Oauth2Callback.htm';
/**
 * minimum TTL of the access_token before we are going to refresh it
 */
const DEFAULT_REFRESH_THRESHOLD_SECONDS = 300;
const DEFAULT_SCOPES = ['profile'];

let _oauthClientManager: OauthClientManager =  new OauthClientManager();

class Dummy implements ILogger {
  debug(msg: string): void { }
}

let LOGGER: ILogger = new Dummy();

declare global {
  interface Window {
    oauth2: WindowOauth2;
  }
}

const setDebugLoggingEnabled = async (isEnabled: boolean) => {
  if (isEnabled) {
    const loggerPromise = import(/* webpackChunkName: "wrapper" */ './loggerWrapper');
    const logging = (await (loggerPromise)).default();
    logging.configure(isEnabled);
    LOGGER = logging.getLogger('oauth-client-nn main');
    console.warn('Oauth-Client-NN: Debug logging enabled. TIP: To show logs set log level in browser console to "verbose"');
  }

};

export const getConfiguration = (key: string) => {
  if (!key || !configuration[key]) {
    throw new Error('unknown key to get or create an oauth client');
  }
  return configuration[key];
};

const getOrCreateOauthClient = async (key: string): Promise<OauthClient> => {
  let oauthClient = _oauthClientManager.getClient(key)?.client;
  if (!oauthClient) {
    const conf = getConfiguration(key);
    const OauthClientPromise = import(/* webpackChunkName: "wrapper" */ './oauthClientWrapper');

    const redirect_uri = `${window.location.origin}${conf.CALLBACKURL || DEFAULT_RELATIVE_OAUTH_CALLBACK_URL}`;
    const scope = (conf.SCOPES || DEFAULT_SCOPES).join(' ');
    const createOauthClient = (await (OauthClientPromise)).default;

    LOGGER.debug(`Creating Oauth Client for key [${key}] scope [${scope}]`);

    oauthClient = createOauthClient({
          authority: conf.REALM.OAUTH_AUTHORITY_PREFIX,
          clientId: conf.CLIENTID,
          redirect_uri: redirect_uri,
          scope: scope,
          tenantId: conf.TENANTID,
          storageKey: conf.STORAGEKEY,
          refreshThreshold: DEFAULT_REFRESH_THRESHOLD_SECONDS,
        });
    
    _oauthClientManager.addClient(key, oauthClient);
}
  return oauthClient;
};

const getClient = () => _oauthClientManager.getRecentClient();

const initializeAuthorizationCodeFlow = async (key: string): Promise<TokenSet> => {
  let oauthClientInfo = _oauthClientManager.getClient(key);

  if (oauthClientInfo && oauthClientInfo.initialized) {
    console.warn('"initializeAuthorizationCodeFlow" should only be called ONCE in your app! use "getTokenset" instead if you want to tokenset again');
    return oauthClientInfo.client.getTokenset();
  }
  LOGGER.debug(`${key} initializing codeflow`);

  const conf = getConfiguration(key);
  const oauthClient = await getOrCreateOauthClient(key);
  const sessionInfo = await getSessionInfo(conf.REALM);

  // debugging if this condition makes sense to keep evaluating
  const isSessionInfoStillValid = sessionInfoIsStillValidForCurrentUser(oauthClient, sessionInfo);
  LOGGER.debug(`is sessionInfo still valid: ${isSessionInfoStillValid}`);

  // getTokenset will retrieve the token from local storage (if any) AND side-effect: will refresh it when getTokenset detects it is expired
  const tokenset = await oauthClient.getTokenset();
  if (isSessionInfoStillValid && tokenset) {
    // it only makes sense to init refreh stuff / absolute logout logic
    // when we currently _have_ a valid session & tokenset to base this off
    LOGGER.debug('Initializing timer for refresh_token');
    await oauthClient.initRefreshTimer();

    if (conf.LOGOUTURL && sessionInfo) {
      await scheduleAbsoluteLogout(conf.LOGOUTURL, sessionInfo.maxSessionExpirationTime);
    }

    _oauthClientManager.initializeClient(key);
    return tokenset;
  }

  // signIn will always cause browser redirect loop to oauth login flow
  // as such we can not resolve our promise because our clients
  // might rely on the resolve of the signIn() function to then
  // execute API calls which will all fail (client will start)
  oauthClient.signIn();
  return new Promise(() => { }); // explicitly NOT resolving to prevent app start
};

const sessionInfoIsStillValidForCurrentUser = (oauthClient: OauthClient, currentSessionInfo: SessionInfo) => {
  if (!currentSessionInfo) {
    return false;
  }

  // verify if the retrieved sessionInfo is synced with the stored state
  // why is it here: we _might_ have re-logged in with a different user/expiration time
  // on a non-oauth2 page and thus the sessionInfo is out of sync with what we have
  // stored in the sessionStorage
  const sessionInfoFromStore = oauthClient.store.getMetaData() as SessionInfo;
  return sessionInfoFromStore?.username === currentSessionInfo.username;
};

const scheduleAbsoluteLogout = async (logoutUrl: string, maxSessionExpirationTime: Date) => {
  if (!logoutUrl) {
    return;
  }

  const absoluteLogoutInMilliseconds = maxSessionExpirationTime.getTime() - new Date().getTime();
  if (absoluteLogoutInMilliseconds > 0) {
    // the maximum authentication session duration is still in the future. Schedule a logout for that moment
    // because the SRP does not allow us to prolong the session after that moment
    setTimeout(() => {
      location.assign(logoutUrl);
    }, absoluteLogoutInMilliseconds);
  }
};

const getTokenset = async (key: string): Promise<TokenSet> => {
  const oauthClient = await getOrCreateOauthClient(key);
  LOGGER.debug(`[getTokenSet] key: ${key} ${oauthClient.clientId}`);
  return oauthClient.getTokenset();
};

const cleanupSessionState = async (key: string): Promise<void> => {
  const oauthClient = await getOrCreateOauthClient(key);
  oauthClient.signOut();
};

const handleAuthorizationCodeFlowCallback = async (key: string): Promise<void> => {
  const oauthClient = await getOrCreateOauthClient(key);

  // retrieve the current session information for the user from SRP
  // This contains the max duration of the session before we need to
  // log them out (session can no longer be extended)
  const conf = getConfiguration(key);
  const sessionInfo = await getSessionInfo(conf.REALM);
  oauthClient.store.setMetaData(sessionInfo);

  return oauthClient.signInCallback();
};

const signIn = async (key: string): Promise<TokenSet> => {
  console.warn('"signIn" is deprecated! Use "initializeAuthorizationCodeFlow" instead.');
  return initializeAuthorizationCodeFlow(key);
}

const signOut = async (key: string): Promise<void> => {
  console.warn('"signOut" is deprecated! Use "cleanupSessionState" instead.');
  cleanupSessionState(key);
};

const signInCallback = async (key: string): Promise<void> => {
  console.warn('"signInCallback" is deprecated! Use "handleAuthorizationCodeFlowCallback" instead.');
  handleAuthorizationCodeFlowCallback(key);
};

window.oauth2 = {
  initializeAuthorizationCodeFlow,
  signIn,
  getTokenset,
  cleanupSessionState,
  signOut,
  handleAuthorizationCodeFlowCallback,
  signInCallback,
  hasSsoCookie,
  getClient,
  setDebugLoggingEnabled,
  resetLoadedClient: () => {
    _oauthClientManager = new OauthClientManager();
    setDebugLoggingEnabled(false);
  }
}
